<template>
	<div class="my-32 mt-40 grid grid-cols-5" id="products" data-aos="fade-up">
		<div class="col-span-full md:col-span-3 lg:col-span-2 flex flex-col">
			<h2 class="font-dm-sans font-bold text-5xl md:px-0 px-16 md:text-left text-center tracking-tight">
				Our first token is out NOW stay tuned!
			</h2>
		</div>
		<img src="@/assets/images/headphones.webp" alt="Tokens" class="h-full transform scale-500 md:hidden col-span-full
			my-40 -z-1" loading="lazy">
		<div class="col-start-2 md:col-start-4 col-span-3 md:col-span-2 flex items-center justify-center">
			<a class="px-8 md:px-20 py-4 flex items-center justify-center bg-brand-purple rounded-lg text-white
				transition-all duration-500 hover:shadow-lg md:mt-0 mt-8"
			   href="https://melodity.org" target="_blank" rel="noopener">
				Stay tuned!
			</a>
		</div>
		<div class="font-dm-sans font-bold text-brand-purple col-span-full mt-40 h-96 -z-1 md:block hidden">
<!--			<img src="@/assets/images/headphones.webp" alt="Tokens" class="h-full transform scale-700" loading="lazy">-->
		</div>
	</div>
</template>

<script>
import urls from "@/composition/urls";

export default {
	name: "Token",
	inject: ["intersection_observer"],
	setup() {
		return {
			...urls()
		}
	},
	mounted() {
		// this.intersection_observer.value.observe(this.$el)
	}
}
</script>

<style scoped>

</style>
