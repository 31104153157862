<template>
	<main class="grid md:grid-cols-8 xl:grid-cols-12 w-full mt-32">
		<div class="xl:col-start-3 xl:p-0 md:p-8 col-span-8 relative flex flex-col">
			<home></home>
			<mission></mission>
			<ecosystem></ecosystem>
			<token></token>
		</div>
	</main>
</template>

<script>
import Home from "@/components/Home";
import Mission from "@/components/Mission";
import Ecosystem from "@/components/Ecosystem";
import Token from "@/components/Token";

export default {
	name: 'Landing',
	components: {
		Token,
		Ecosystem,
		Mission,
		Home,
	},
}
</script>

<style scoped lang="scss">
</style>