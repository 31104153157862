<template>
  <div class="my-32 grid grid-cols-5" id="ecosystem" data-aos="fade-up-left">
    <div class="col-span-full md:col-span-2 flex flex-col md:text-left text-center">
      <h2 class="font-dm-sans font-bold text-4xl md:mb-0 mb-28">
        Our ecosystem
      </h2>
      <img src="@/assets/images/girl.webp" alt="Girl listening music"
           class="h-full transform scale-225 md:hidden mx-auto mb-12" loading="lazy">
      <p class="mt-16 font-nunito text-lg px-6 md:px-0">
        The ever-growing Do inc.™ environment connects our loved music with
        the new awesome and powerful world of blockchain and decentralization.
        <br>
        Do a new World, with us!
      </p>
    </div>
    <div class="col-span-3 hidden md:flex items-center justify-center -z-1">
      <img src="@/assets/images/girl.webp" alt="Girl listening music" class="h-full transform scale-400"
           loading="lazy">
    </div>
    <h4 class="font-dm-sans font-bold text-brand-purple col-span-full md:col-span-2 md:mt-40 mt-20 md:mx-0 mx-auto">
      We are building a whole ecosystem
    </h4>
    <div class="col-span-full grid grid-cols-2 md:grid-cols-3 mt-12 md:p-0 p-4">
      <div v-for="(elem, id) of texts" :key="id" :class="borderClasses[id]"
           class="flex items-center justify-center font-bold text-xl p-12">
        {{ elem }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ecosystem",
  inject: ["intersection_observer"],
  data: () => ({
    texts: [
      "Tokens",
      "DApps",
      "Blockchain",
      "NFT",
      "Apps",
      "NGO",
    ]
  }),
  computed: {
    borderClasses() {
      return this.texts.map((v, k) => {
        switch (k) {
          case 0:
            return "border-b border-r"
          case 3:
            return "border-t md:border-r md:border-b-0 md:border-l-0 border-b border-l"
          case 1:
            return "border-l border-b md:border-r"
          case 4:
            return "md:border-l border-t border-r"
          case 2:
            return "border-b md:border-l md:border-t-0 md:border-r-0 border-t border-r"
          case 5:
            return "border-t border-l"
        }
      });
    }
  },
  mounted() {
    // this.intersection_observer.value.observe(this.$el)
  }
}
</script>

<style scoped>

</style>
