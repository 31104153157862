import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "@/assets/main.css"
import "@/assets/subscribe.css"
import "boxicons/css/boxicons.min.css"
import "aos/dist/aos.css"
import Aos from "aos"

Aos.init()

createApp(App).use(router).mount('#app')
