<template>
	<div class="md:my-96 my-28 grid grid-cols-5" id="mission" data-aos="fade-up-right">
		<div class="col-span-3 hidden md:flex items-center justify-center -z-1">
			<img src="@/assets/images/boy.webp" alt="Singing boy" class="h-full transform scale-400" loading="lazy">
		</div>
		<div class="col-span-full md:col-span-2 flex flex-col md:text-left text-center">
			<h2 class="font-dm-sans font-bold text-4xl md:mb-0 mb-28">
				Decentralizing music.
			</h2>
			<img src="@/assets/images/boy.webp" alt="Singing boy"
			     class="h-full transform scale-225 md:hidden mx-auto mb-12" loading="lazy">
			<p class="mt-16 font-nunito text-lg px-6 md:px-0">
				Do inc.™ gives all artists a place where to freely express their feelings, breaking the chains of
				censorship all around the world, and returning the ownership of success to the artists.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "Mission",
	inject: ["intersection_observer"],
	mounted() {
		// this.intersection_observer.value.observe(this.$el)
	}
}
</script>

<style scoped>

</style>