export default function urls() {
    return {
        url: {
            home: "/#home",
            mission: "/#mission",
            ecosystem: "/#ecosystem",
            products: "/#products",
            subscribe: "/subscribe",
            privacy_policy: "https://app.termly.io/document/privacy-policy/82476885-9144-4483-af30-804968838f49",
            cookie_policy: "https://app.termly.io/document/cookie-policy/7558c164-c72f-40df-93d0-10ca7b0e9c47",
            terms_and_conditions: "https://app.termly.io/document/terms-of-use-for-saas/5638a323-a149-44ee-880c-eb29b5dc9b71",
        },
    }
}
