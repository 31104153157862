<template>
  <header
      class="fixed z-50 top-0 left-0 w-screen max-w-screen bg-white bg-opacity-80 grid md:grid-cols-8 xl:grid-cols-12">
    <div class="xl:col-start-3 col-span-8 relative">
      <nav class="grid grid-cols-8 items-center w-full p-2 pt-6">
        <div class="col-span-2 object-contain flex items-center justify-start w-full max-h-20">
          <img src="@/assets/images/logo.svg" alt="Do inc." class="object-contain lg:w-1/2 max-h-20">
        </div>
        <div class="hidden col-span-3 col-start-6 lg:flex items-center justify-center mr-12">
          <a v-for="(elem, id) of uris" :key="id" :href="elem.url"
             class="mx-auto p-3 font-dm-sans font-bold" :class="{'nav-active': elem.active}">
            {{ elem.title }}
          </a>
        </div>
        <div class="col-start-8 flex items-center justify-center lg:hidden text-4xl cursor-pointer"
             @click="openNav">
          <i class='bx bx-menu-alt-right'></i>
        </div>
        <div class="flex flex-col lg:hidden p-4 fixed right-0 top-0 bottom-0 h-screen w-screen transform transition-all
					duration-500 ease-in-out bg-white text-xl"
             :class="navClass">
          <img src="@/assets/images/logo.svg" alt="Do Labs" class="object-contain w-full max-h-20">
          <div class="fixed top-4 right-4 p-4 text-4xl cursor-pointer" @click="closeNav">
            <i class='bx bx-x'></i>
          </div>
          <a v-for="(elem, id) of uris" :key="id" :href="elem.url" @click="closeNav"
             class="mx-auto w-1/2 p-4 font-dm-sans font-bold mb-4 text-center"
             :class="{'nav-active': elem.active}">
            {{ elem.title }}
          </a>
        </div>
      </nav>
    </div>
  </header>
  <router-view/>
  <footer class="w-full bg-white bg-opacity-50 grid md:grid-cols-8 xl:grid-cols-12 -mt-16">
    <div class="xl:col-start-3 col-span-8 relative">
      <nav class="grid grid-cols-8 items-center w-full p-2 pt-6">
        <div class="col-span-2 object-contain flex items-center justify-center w-full max-h-20">
          <img src="@/assets/images/logo.svg" alt="Do inc." class="object-contain w-full max-h-20">
        </div>
        <div class="hidden col-span-3 col-start-6 lg:flex items-center justify-center">
          <a v-for="(elem, id) of uris" :key="id" :href="elem.url"
             class="mx-auto p-3 font-dm-sans font-bold" :class="{'nav-active': elem.active}">
            {{ elem.title }}
          </a>
        </div>
        <div
            class="col-span-2 col-start-5 md:col-start-1 md:col-span-full w-full flex items-center text-2xl md:text-3xl">
          <a class="p-2 ml-auto" href="https://twitter.com/DoEcosystem" target="_blank" rel="noopener">
            <i class='bx bxl-twitter'></i>
          </a>
          <a class="p-2" href="https://www.facebook.com/doecosystem" target="_blank" rel="noopener">
            <i class='bx bxl-facebook-circle'></i>
          </a>
          <a class="p-2" href="https://www.instagram.com/doecosystem/" target="_blank" rel="noopener">
            <i class='bx bxl-instagram-alt'></i>
          </a>
          <a class="p-2" href="https://www.linkedin.com/company/do-inc-co/" target="_blank" rel="noopener">
            <i class='bx bxl-linkedin'></i>
          </a>
        </div>
        <div class="row-start-3 col-span-full grid grid-cols-8 my-8 text-xs">
          <div class="col-span-full order-2 md:order-1 md:col-span-2 flex items-center justify-center w-full">
            Copyright &copy; {{ year }} Do inc.&trade;
          </div>
          <div
              class="col-span-full order-1 md:order-2 md:col-span-5 md:col-start-4 lg:col-span-3 lg:col-start-6 flex items-center justify-center">
            <button onclick="displayPreferenceModal()" class="mx-auto p-3">
              Cookie preferences
            </button>
            <a v-for="(elem, id) of policies" :key="id" :href="elem.url" class="mx-auto p-3" target="_blank"
               rel="nofollow noopener">
              {{ elem.title }}
            </a>
          </div>
        </div>
      </nav>
    </div>
  </footer>
</template>

<script>
import urls from "@/composition/urls";

export default {
  name: 'App',
  setup() {
    return {
      ...urls()
    }
  },
  data() {
    return {
      uris: [
        {
          title: "Home",
          url: this.url.home,
          active: false
        },
        {
          title: "Mission",
          url: this.url.mission,
          active: false
        },
        {
          title: "Ecosystem",
          url: this.url.ecosystem,
          active: false
        },
        {
          title: "Products",
          url: this.url.products,
          active: false
        },
      ],
      policies: [
        {
          title: "Privacy policy",
          url: this.url.privacy_policy,
        },
        {
          title: "Cookie policy",
          url: this.url.cookie_policy,
        },
        {
          title: "Terms & Conditions",
          url: this.url.terms_and_conditions,
        },
      ],
      year: null,
      observer: null,
      nav_bar_opened: false,
    }
  },
  methods: {
    openNav() {
      this.nav_bar_opened = true
    },
    closeNav() {
      this.nav_bar_opened = false
    },

    resetActiveNav() {
      for (let elem of this.uris) {
        elem.active = false
      }
    },
    checkCurrentHash() {
      this.resetActiveNav()

      switch (this.$route.hash) {
        case this.uris[1].url.replace("/", ""):
          this.uris[1].active = true
          break
        case this.uris[2].url.replace("/", ""):
          this.uris[2].active = true
          break
        case this.uris[3].url.replace("/", ""):
          this.uris[3].active = true
          break
        case this.uris[4].url.replace("/", ""):
          this.uris[4].active = true
          break
        case this.uris[0].url.replace("/", ""):
        default:
          this.uris[0].active = true
      }

      if (this.$route.hash === "" || this.$route.hash === null) {
        if (this.$route.name === "Team") {
          this.resetActiveNav()
          this.uris[3].active = true
        }
      }
    },
    intersectionObserver(entries) {

      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.resetActiveNav()

          // match one of the current intersected element with the url
          switch (entry.target.id) {
            case this.uris[1].url.replace("/#", ""):
              this.uris[1].active = true
              break
            case this.uris[2].url.replace("/#", ""):
              this.uris[2].active = true
              break
            case this.uris[3].url.replace("/#", ""):
              this.uris[3].active = true
              break
            case this.uris[4].url.replace("/#", ""):
              this.uris[4].active = true
              break
            case this.uris[0].url.replace("/#", ""):
            default:
              this.uris[0].active = true
          }
        }
      });
    }
  },
  computed: {
    navClass() {
      return {
        "translate-x-full": !this.nav_bar_opened,
        "translate-x-0": this.nav_bar_opened,
      }
    }
  },
  watch: {
    "$route": "checkCurrentHash"
  },
  created() {
    this.year = (new Date()).getFullYear()
    this.checkCurrentHash()
  },
}
</script>

<style scoped lang="scss">
.nav-active {
  @apply relative;
  &:before {
    content: "";
    @apply h-1 w-full rounded-full bg-brand-purple absolute bottom-0 left-1/2 transform -translate-x-1/2;
  }
}
</style>
