import {createRouter, createWebHistory} from 'vue-router'
import Landing from "@/views/Landing";

const routes = [
    {
        path: '/',
        name: 'Landing',
        component: Landing,
    },
    {
        path: '/subscribe',
        name: 'Subscribe',
        component: () => import(/* webpackChunkName: "subscribe" */ "../views/Subscribe")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
