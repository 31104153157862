<template>
	<div class="my-32 grid grid-cols-5 relative" id="home">
		<div class="col-span-full order-2 md:order-1 md:col-span-2 flex flex-col md:mt-0 -mt-28 md:text-left text-center">
			<h1 class="font-dm-sans font-bold text-7xl sm:text-8xl md:leading-none leading-closest">
				A block,
				<br>
				A song,
				<br>
				A story.
			</h1>
			<p class="mt-16 font-nunito text-lg px-6 md:px-0">
				Do inc.&trade; brings old fashioned music industry to the 21st century
				connecting it to the awesome world of blockchain technology and
				decentralization.
				<br>
				Join us and spread this new love!
			</p>
			<div class="flex md:flex-row flex-col items-center mt-4">
				<a class="px-20 py-4 flex items-center justify-center bg-brand-purple rounded-lg text-white md:mr-8
					transition-all duration-500 hover:shadow-lg mb-6 md:mb-0 md:mt-0 mt-8"
				   :href="url.subscribe">
					Stay tuned!
				</a>
				<a class="border border-gray-300 rounded-full md:h-12 md:w-12 h-20 w-20 flex items-center justify-center
					md:text-xl text-3xl"
				   :href="url.mission">
					<i class='bx bx-down-arrow'></i>
				</a>
			</div>
		</div>
		<div class="col-span-full md:col-span-3 md:order-2 order-1 flex items-center justify-center -z-1">
			<img src="@/assets/images/slide-1.webp" alt="Musical instruments - abstract"
			     class="h-full transform scale-300 mr-auto md:m-0 md:scale-800 md:relative absolute -left-96 md:left-0">
			<img src="@/assets/images/slide-1.webp" alt="Musical instruments - abstract"
			     class="h-full transform scale-300 ml-auto md:hidden absolute -right-80">
		</div>
	</div>
</template>

<script>
import urls from "@/composition/urls";

export default {
	name: "Home",
	inject: ["intersection_observer"],
	setup() {
		return {
			...urls()
		}
	},
	mounted() {
		// this.intersection_observer.value.observe(this.$el)
	}
}
</script>

<style scoped>

</style>